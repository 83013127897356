.Info {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #a2c0ce;
  overflow-y: hidden;
}

.InfoInnerText {
  min-height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.InfoInnerTextBottom {
  min-height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.InfoTitle {
  text-align: center;
 font-size: 120%;
 font-weight: 500;
 color: #343F39;
 font-family: "Poppins Black";
}
.InfoText {
 text-align: center;
 width: 80%;
  max-width: 600px;
 font-size: 90%;
 margin-top: -4px;
 color: #343F39;
 font-family: "Poppins";
}

.InfoInnerIconFirst {
  height: 20%;
  width: 77%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.InfoIconFirst {
    width: 95%;
}

.InfoImage {
  width: 100%;
  max-width: 660px;
  object-fit: cover;
  box-shadow: 1px 2px 2px rgb(87, 87, 87);
  opacity: 0.95;
}

.InfoIcon {
  width: 70%;
  max-width: 400px;
}