.Start {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #a2c0ce;
}

.StartInner {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.StartText {
  height: 30%;
  width: 90%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.StartLogo {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.StartSocial {
  height: 30%;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.StartInnerText {
  width: 96%;
}

.StartInnerLogo {
  width: 40%;
  height:auto;
}

@media only screen and (min-width: 800px) {
  
}
