.Teams {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #575757;
  overflow-y: hidden;
}

.TeamsInnerText {
  min-height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TeamsTitle {
  text-align: center;
 font-size: 120%;
 font-weight: 500;
 color: #a2c0ce;
 font-family: "Poppins Black";
}
.TeamsText {
 text-align: center;
 width: 80%;
  max-width: 600px;
 font-size: 90%;
 margin-top: -4px;
 color: #a2c0ce;
 font-family: "Poppins";
}


.TeamsImage {
  height: 40vh;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TeamsImageCarousel {
  height: 90%;
  width: 100%;
}

.TeamsImageSlider {
  height: 90%;
  width: 100%;
}
.TeamsImageSlide {
  height: 90%;
  width: 100%;
}
.TeamsImageInner {
  height: auto;
  max-height: 32vh;
  width: 95%;
  object-fit: contain;
  box-shadow: 1px 2px 2px rgb(87, 87, 87);
  opacity: 0.95;
  cursor: pointer;
}

.TeamsButtons {
  height: 20%;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
 font-size: 95%;
 font-weight: 500;
 color: #a2c0ce;
}

.TeamsButtonsText {
  width: 40%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
 font-size: 120%;
 font-weight: 500;
 color: #a2c0ce;
}

.TeamsButton {
  background: none;
	color: inherit;
	border: none;
	padding: 30;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.ButtonArrow {
    width: 30px;
}

.TeamsLowerText {
  min-height: 20vh;
  width: 85%;
  padding-top: 20px;
  max-width: 700px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.TeamsLowerInnerText {
  
  font-size: 80%;
 margin-top: -7px;
 color: #a2c0ce;
 font-family: "Poppins";
}

.TeamsBottom {
  width: 100%;
  max-width: 660px;
  position: relative;
  margin-bottom: -4px;
}
.TeamsBottomText {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  text-align: center;
 font-size: 200%;
 font-weight: 500;
 background-color: #575757;
 font-family: "Poppins Black";
 color: #a2c0ce;
	cursor: pointer;
  
}

.TeamsCalendarImg {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 1px 2px 2px rgb(87, 87, 87);
  opacity: 0.95;
	cursor: pointer;
}