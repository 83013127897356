.Contact {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #a2c0ce;
  overflow-y: hidden;
}

.ContactOpening {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.ContactInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
}


.WebmasterDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 5%;
  width: 100%;
}

.ContactSocial {
  height: 20vh;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}