.Scoring {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #a2c0ce;
  overflow-y: hidden;
  padding-bottom: 7vh;
}

.ScoringInnerText {
  min-height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ScoringTitle {
  text-align: center;
 font-size: 130%;
 font-weight: 500;
 color: #575757;
 font-family: "Poppins Black";
}
.ScoringText {
 text-align: center;
 width: 80%;
  max-width: 600px;
 font-size: 100%;
 margin-top: -4px;
 color: #575757;
 font-family: "Poppins";
}


.ScoringImage {
  height: 40vh;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ScoringImageCarousel {
  height: 90%;
  width: 100%;
}

.ScoringImageSlider {
  height: 90%;
  width: 100%;
}
.ScoringImageSlide {
  height: 90%;
  width: 100%;
}
.ScoringImageInner {
  height: auto;
  max-height: 32vh;
  width: 95%;
  object-fit: contain;
  opacity: 0.95;
}

.ScoringButtons {
  height: 20%;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
 font-size: 95%;
 font-weight: 500;
 color: #575757;
}

.ScoringButtonsText {
  width: 40%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
 font-size: 120%;
 font-weight: 500;
 color: #575757;
}

.ScoringButton {
  background: none;
	color: inherit;
	border: none;
	padding: 30;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.ButtonArrow {
    width: 30px;
}